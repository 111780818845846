<template>
  <b-container fluid>
    <div class="wrapper p-3 p-md-5">
      <report-summary :report="report" v-show="!isLoading" />

      <tradeline-component
        ref="tradeline"
        :items="items"
        class="my-5"
        v-show="!isLoading"
      />

      <button-component
        status="create"
        @new-order="onClickCreate"
        v-show="!isLoading"
      />
      <b-overlay
        :show="isLoading || isSubmitting"
        :no-wrap="isSubmitting"
        :fixed="isSubmitting"
      ></b-overlay>
    </div>
    <div class="my-5 py-3" />
  </b-container>
</template>
<script>
import { apiService } from '../../scripts/services'
import TradelineComponent from '../Base/TradelineComponent.vue'
import ButtonComponent from '../common/ButtonComponent.vue'
import ReportSummary from '../common/ReportSummary.vue'

export default {
  components: {
    TradelineComponent,
    ButtonComponent,
    ReportSummary
  },
  name: 'Birchwoood',
  props: {},
  mounted() {
    this.isLoading = true
    apiService.getReports(this.$route.params.reportId).then((r) => {
      this.report = r
      apiService.getTradelines().then((rr) => {
        this.items = rr
        setTimeout(() => {
          this.isLoading = false
        }, 250)
      })
    })
  },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      items: [],
      report: {}
    }
  },
  methods: {
    onClickCreate() {
      // do all the task to submit to apiService
      const payload = {
        report: this.report,
        tradelines: this.items.filter((x) => x._showDetails === true) // this.$refs.tradeline.getPayload()
      }

      this.isSubmitting = true

      apiService
        .submitOrder(payload)
        .then(() => {
          this.$router.push('/orders')
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Unexpected Error Encountered')
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bg-blue {
  background-color: #003366;
}
.text-blue {
  color: #003366;
}
.thvertical {
  writing-mode: vertical-rl !important;
  text-orientation: upright !important;
}
.wfour {
  width: 4%;
}
.wfive {
  width: 5%;
}
.wsix {
  width: 6%;
}
.weight {
  width: 8%;
}
.wten {
  width: 10%;
}
.weleven {
  width: 11%;
}
.wtwelve {
  width: 12%;
}
.wfourteen {
  width: 14%;
}

table > thead > tr > th {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #fff;
  background-color: #17a2b8 !important;
}
table > thead > tr:first-of-type {
  display: none;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  background-color: #0033660f;
}
.table th,
.table td {
  font-size: 0.86rem;
}
</style>
